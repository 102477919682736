import { clsx } from 'clsx/lite';
import type { ReactNode } from 'react';
import {
  type TooltipProps as TooltipProps_,
  type TooltipTriggerComponentProps as TooltipTriggerComponentProps_,
  OverlayArrow,
  Tooltip as Tooltip_,
  TooltipTrigger,
} from 'react-aria-components';
import type { Simplify } from 'type-fest';

import { overlayArrowStyles, tooltipStyles } from './tooltip.css.js';

export type TriggerProps = Omit<TooltipTriggerComponentProps_, 'children'>;

export type TooltipProps = Simplify<
  Omit<TooltipProps_, 'children'> & {
    children: ReactNode;
  }
>;

function Tooltip({ children, className, ...props }: TooltipProps) {
  return (
    <Tooltip_
      className={clsx(tooltipStyles, className)}
      data-test="tooltip"
      offset={props.offset ?? 4}
      {...props}
    >
      <OverlayArrow>
        <svg className={overlayArrowStyles} height={8}>
          <path d="M0 0 L8 8 L16 0" />
        </svg>
      </OverlayArrow>
      {children}
    </Tooltip_>
  );
}

// interface TooltipTriggerElementProps extends AriaButtonOptions<'span'> {
//   children: ReactNode;
//   trigger?: string;
// }

// https://github.com/adobe/react-spectrum/issues/5733
// function TooltipTriggerElement(props: TooltipTriggerElementProps) {
//   // const triggerRef = useRef();
//   // const { buttonProps } = useButton(props, triggerRef);

//   // const children = Children.toArray(props.children);
//   // if (
//   //   !children.some(child => isValidElement(child) && child.type === Tooltip)
//   // ) {
//   //   throw new TypeError('TooltipTrigger must contain a Tooltip component');
//   // }

//   const mergedProps = mergeProps();
//   // buttonProps,
//   // if there are children, and the first child is a valid element, and the first child is NOT a Button,
//   // then we want to merge the props
//   // (
//   //   children.length > 0 &&
//   //     isValidElement(children[0]) &&
//   //     children[0].type !== Button
//   // ) ?
//   //   children[0].props
//   // : null,
//   // {
//   //   ref: triggerRef,
//   // },

//   const otherProps: Record<string, string> = {};
//   if (props.trigger) {
//     otherProps['data-trigger'] = props.trigger;
//   }

//   return (
//     <span
//       className={triggerStyles}
//       data-test="tooltip-trigger"
//       // {...mergedProps}
//       {...otherProps}
//     >
//       {children}
//     </span>
//   );
// }

// function TooltipTrigger({
//   children,
//   trigger,
//   ...rest
// }: PropsWithChildren<TriggerProps>) {
//   return (
//     <TooltipTrigger_ trigger={trigger} {...rest}>
//       <TooltipTriggerElement trigger={trigger}>
//         {children}
//       </TooltipTriggerElement>
//     </TooltipTrigger_>
//   );
// }

export { Tooltip, TooltipTrigger };
